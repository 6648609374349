import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { openNotification } from '../../../../state/notification/notification.atom';
import { postTransferTokensRequest } from './api';
import { useCurrentWallet } from '../../../../state/wallets/current-wallet.atom';
import { unshiftTransaction } from '../../../../state/transactions/transactions.atom';

const FORM_FIELD_NAMES = {
  address: 'receiver-address',
  tokensAmount: 'tokens-amount',
  messageText: 'message-text',
};

const SendModal: React.FC<TModalProps> = (props) => {
  const { isVisible, onClose } = props;

  const { walletId } = useCurrentWallet();

  const [receiverAddress, setReceiverAddress] = useState<TTokensTransferParams['receiverAddress']>('');
  const [tokensAmount, setTokensAmount] = useState<string>('');
  const [messageText, setMessageText] = useState<TTokensTransferParams['messageText']>('');

  const [isTransferOngoing, setIsTransferOngoing] = useState<boolean>(false);

  const handleClose = (): void => {
    setReceiverAddress('');
    setTokensAmount('');
    setMessageText('');

    setIsTransferOngoing(false);
    onClose();
  };

  const handleReceiverAddressChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    event.preventDefault();
    setReceiverAddress(event.target.value);
  };

  const handleTokensAmountChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    event.preventDefault();
    setTokensAmount(event.target.value);
  };

  const handleMessageTextChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    event.preventDefault();
    setMessageText(event.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (isTransferOngoing) {
      return;
    }

    setIsTransferOngoing(true);

    if (!receiverAddress) {
      return openNotification('Invalid address');
    }

    const normalizedTokens = +tokensAmount;
    if (isNaN(normalizedTokens)) {
      return openNotification('Invalid amount. Please, use numbers');
    }

    if (!normalizedTokens) {
      return openNotification('Cannot send 0 tokens');
    }

    const transferData: TTokensTransferParams = {
      receiverAddress,
      tokensAmount,
    };

    if (messageText) {
      transferData.messageText = messageText;
    }

    postTransferTokensRequest(walletId, transferData)
      .then(transaction => {
        const fakeCreatedAt = new Date();
        const fakeId = -fakeCreatedAt;
        unshiftTransaction({
          ...transaction,
          id: fakeId,
          createdAt: `${fakeCreatedAt}`,
        });
      })
      .catch(() => null)
      .finally(handleClose);
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
    >
      <DialogContent>
        <TextField
          autoFocus
          required
          margin='normal'
          id={FORM_FIELD_NAMES.address}
          name={FORM_FIELD_NAMES.address}
          label='Receiver address'
          placeholder='Enter receiver address'
          type='text'
          fullWidth
          variant='standard'
          style={{ minWidth: 512 }}
          onChange={handleReceiverAddressChange}
          value={receiverAddress}
        />
        <TextField
          required
          margin='normal'
          id={FORM_FIELD_NAMES.tokensAmount}
          name={FORM_FIELD_NAMES.tokensAmount}
          label='Tokens'
          placeholder='Enter tokens amount'
          type='text'
          fullWidth
          variant='standard'
          style={{ minWidth: 512 }}
          onChange={handleTokensAmountChange}
          value={tokensAmount}
        />
        <TextField
          margin='normal'
          id={FORM_FIELD_NAMES.messageText}
          name={FORM_FIELD_NAMES.messageText}
          label='Comment'
          placeholder='Enter comment'
          type='text'
          fullWidth
          variant='standard'
          style={{ minWidth: 512 }}
          onChange={handleMessageTextChange}
          value={messageText}
        />
      </DialogContent>
      <DialogActions>
        <Button type='submit' disabled={isTransferOngoing}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendModal;
