import React from 'react';
import Chip, { ChipOwnProps } from '@mui/material/Chip';

type WalletActionButtonProps = {
  title: 'Receive' | 'Send';
  iconSlot: ChipOwnProps['icon'];
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

const WalletActionButton: React.FC<WalletActionButtonProps> = (props) => {
  const { title, iconSlot, onClick } = props;

  return (
    <Chip
      icon={iconSlot}
      label={title}
      variant='outlined'
      onClick={onClick}
      style={{ color: '#e2e2e2' }}
    />
  );
}

export default WalletActionButton;
