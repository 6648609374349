import React from 'react';
import { Snackbar } from '@mui/material';

import { closeNotification, useNotificationMessage } from '../../state/notification/notification.atom';

const Notification: React.FC = () => {
  const notificationMessage = useNotificationMessage();

  const isNotificationVisible = !!notificationMessage;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      open={isNotificationVisible}
      onClose={closeNotification}
      message={notificationMessage}
    />
  );
};

export default Notification;
