import React, { useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppTheme from '../../styles/app-theme';
import BalanceCard from './balance-card';
import Notification from '../../features/notification';
import TransactionsList from './transactions-list';
import { clearTransactions, updateTransactions } from '../../state/transactions/transactions.atom';
import { useCurrentWallet } from '../../state/wallets/current-wallet.atom';
import { getTransactionsRequest } from './transactions-list/api';

const HomePage: React.FC = () => {
  const { id: walletDbId } = useCurrentWallet();

  useEffect(() => {
    // TODO: this is actually stupid, change to `null`
    if (walletDbId === -1) {
      return;
    }

    getTransactionsRequest(walletDbId)
      .then(transactions => {
        // TODO: sort by query params on the server side
        const sortedTransactions = sortTransactions(transactions);
        updateTransactions(sortedTransactions);
      })
      .catch(() => clearTransactions());
  }, [walletDbId]);

  const sortTransactions = (transactionsToSort: TTransaction[]) => transactionsToSort.sort((currentTransaction, nextTransaction) => {
    const [currentTransactionCreatedAt, nextTransactionCreatedAt] = [
      currentTransaction.createdAt,
      nextTransaction.createdAt,
    ].map(createdAt => +(new Date(createdAt)));

    return nextTransactionCreatedAt - currentTransactionCreatedAt;
  })

  return (
    <>
      <AppTheme>
        <CssBaseline enableColorScheme />
        <Box sx={{ display: 'flex' }}>
          <Box
            component='main'
            sx={(theme) => ({
              flexGrow: 1,
              backgroundColor: alpha(theme.palette.background.default, 1),
              overflow: 'auto',
            })}
            marginTop='20px'
          >
            <Stack
              spacing={2}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                mx: 3,
                pb: 5,
                mt: { xs: 8, md: 0 },
              }}
            >
              <BalanceCard />
              <TransactionsList />
            </Stack>
          </Box>
        </Box>
      </AppTheme>
      <Notification />
    </>
  );
};

export default HomePage;
