import http from '../../../../services/http';

type TransferResult = {
  senderAddress: string;
  receiverAddress: any;
  transactionTonHash: string;
  messageText: string;
};

export const postTransferTokensRequest = async (
  walletTonId: number,
  params: TTokensTransferParams,
): Promise<TransferResult> => http<TransferResult>(`/transfers/${walletTonId}`, {
  method: 'POST',
  json: params,
});
