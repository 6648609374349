import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

import { useCurrentWallet } from '../../../state/wallets/current-wallet.atom';
import WalletActionButton from './wallet-action-button';
import ReceiveModal from './receive-modal';
import SendModal from './send-modal';

const BalanceCard: React.FC = () => {
  const { balance } = useCurrentWallet();

  const [isReceiveModalVisible, setIsReceiveModalVisible] = useState<boolean>(false);
  const [isSendModalVisible, setIsSendModalVisible] = useState<boolean>(false);

  const normalizedBalance = (balance / 1000 / 1000 / 1000).toFixed(9);

  const handleClickReceive: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsReceiveModalVisible(true);
  };

  const handleClickSend: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsSendModalVisible(true);
  };

  return (
    <>
      <Card
        variant='outlined'
        sx={{ height: '100%', flexGrow: 1 }}
        style={{
          background: 'linear-gradient(93deg, rgba(100,100,217,1) 0%, rgba(159,73,235,1) 100%)',
        }}
      >
        <CardContent>
          <Stack
            direction='column'
            style={{
              minWidth: 256,
              display: 'flex',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <Stack sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Stack
                direction='row'
                sx={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <Typography variant='h4' component='p' color='#ffffff'>
                  {normalizedBalance} 💎
                </Typography>
              </Stack>
              <Typography variant='caption' color='#ffffff'>
                Your testnet balance
              </Typography>
              <Stack direction='row' style={{
                marginTop: 16,
                width: '100%',
                justifyContent: 'space-between',
              }}>
                <WalletActionButton
                  title='Receive'
                  iconSlot={<CallReceivedIcon style={{ color: '#e2e2e2' }} />}
                  onClick={handleClickReceive}
                />
                <WalletActionButton
                  title='Send'
                  iconSlot={<CallMadeIcon style={{ color: '#e2e2e2' }} />}
                  onClick={handleClickSend}
                />
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <ReceiveModal
        isVisible={isReceiveModalVisible}
        onClose={() => setIsReceiveModalVisible(false)}
      />
      <SendModal
        isVisible={isSendModalVisible}
        onClose={() => setIsSendModalVisible(false)}
      />
    </>
  );
};

export default BalanceCard;
