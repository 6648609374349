import React from 'react';
import MuiTooltip from '@mui/material/Tooltip';

type TooltipProps = {
  title: string;
  children: JSX.Element;
};

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { title, children } = props;

  return (
    <MuiTooltip title={title}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;