import React from 'react';
import TimelineDot from '@mui/lab/TimelineDot';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

import { TransactionType } from '..';
import Tooltip from '../../../../../components/tooltip';
import { capitalizeFirstCharacter } from '../../../../../utils/string';

type TransactionIconProps = {
  transactionType: TransactionType;
};

const TransactionIcon: React.FC<TransactionIconProps> = (props) => {
  const { transactionType } = props;

  const Icon = transactionType === 'received' ? CallReceivedIcon : CallMadeIcon;
  const tooltipTitle = capitalizeFirstCharacter(transactionType);

  return (
    <TimelineDot>
      <Tooltip title={tooltipTitle}>
        <Icon />
      </Tooltip>
    </TimelineDot>
  );
};

export default TransactionIcon;
