import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../constants/routes';
import AuthForm from '../../features/auth/auth-form';
import { useAuthForm, useAuthFormErrors } from '../../state/user/auth-form.atom';
import { registerUserRequest } from './api';
import { STORAGE_KEYS } from '../../constants/storage-keys';
import { updateUser } from '../../state/user/user.atom';

const SignUpPage: React.FC = () => {
  const { email, password } = useAuthForm();
  const { email: emailError, password: passwordError } = useAuthFormErrors();

  const navigate = useNavigate();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (emailError || passwordError) {
      return;
    }

    const newUser = await registerUserRequest({ email, password });
    const { accessToken, ...userWithoutToken } = newUser;
    updateUser(userWithoutToken);
    localStorage.setItem(STORAGE_KEYS.accessToken, accessToken);
    navigate(ROUTES.home);
  };

  return (
    <AuthForm
      title='Sign up'
      pageToSwitchTitle='Sign in'
      pageToSwitchDescription='Already have an account?'
      pageToSwitchRoute={ROUTES.signIn}
      onSubmit={handleSubmit}
    />
  );
};

export default SignUpPage;
