import { atom, getDefaultStore, useAtomValue } from 'jotai';

const DEFAULT_USER: TAuthenticatedUserWithoutToken = {
  id: -1,
  email: '',
};

const userAtom = atom<TAuthenticatedUserWithoutToken>(DEFAULT_USER);

const setUser = (user: TAuthenticatedUserWithoutToken): void => getDefaultStore().set(userAtom, user);

export const useUser = (): TAuthenticatedUserWithoutToken => useAtomValue(userAtom);

export const updateUser = (user: TAuthenticatedUserWithoutToken) => {
  setUser(user);
};
