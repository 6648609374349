import { STORAGE_KEYS } from '../../constants/storage-keys';
import { HTTP_SERVICE_CONFIG } from './config';

const { baseUrl } = HTTP_SERVICE_CONFIG;

type HttpOptions = RequestInit & {
  json: object;
}

const generateAuthHeader = (): RequestInit['headers'] => {
  const accessToken = localStorage.getItem(STORAGE_KEYS.accessToken);
  if (!accessToken) {
    return {};
  }

  return { Authorization: `Bearer ${accessToken}` };
};

const http = async <ResponseBodyType>(path: URL | string, options: Partial<HttpOptions> = {}): Promise<ResponseBodyType> => {
  let finalOptions: RequestInit = options;
  if (options && options.json && options.method === 'POST') {
    const stringifiedBody = JSON.stringify(options.json);
    delete options.json;
    finalOptions = {
      ...options,
      headers: {
        'Content-Type': 'application/json'
      },
      body: stringifiedBody,
    }
  }

  const authHeader = generateAuthHeader();
  finalOptions.headers = {
    ...finalOptions.headers,
    ...authHeader,
  }

  return fetch(`${baseUrl}${path}`, finalOptions).then<ResponseBodyType>(response => response.json());
};

export default http;
