import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '../../../constants/routes';
import { getUserRequest } from './api';
import { updateUser, useUser } from '../../../state/user/user.atom';
import { STORAGE_KEYS } from '../../../constants/storage-keys';
import Loader from './loader';
import { updateCurrentWallet } from '../../../state/wallets/current-wallet.atom';

type PrivateRouteProps = {
  children: React.ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const { children } = props;

  const user = useUser();

  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);

  const accessToken = localStorage.getItem(STORAGE_KEYS.accessToken);
  const isAuthenticated = !!(accessToken && user.email);

  useEffect(() => {
    if (!(accessToken || isUserLoading)) {
      return;
    }

    getUserRequest()
      .then((userWithWallet) => {
        const { wallet, ...userWithoutWallet } = userWithWallet;
        updateUser(userWithoutWallet);
        updateCurrentWallet(wallet);
      })
      .catch(() => null)
      .finally(() => setIsUserLoading(false));
  }, [accessToken]);

  if (isUserLoading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return <Navigate to={ROUTES.signIn} />;
};

export default PrivateRoute;
