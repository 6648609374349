import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CssBaseline, FormControl, FormLabel, Link, TextField, Typography } from '@mui/material';

import AppTheme from '../../../styles/app-theme';
import { AuthFormContainer, Card } from './styles';
import { updateAuthForm, useAuthForm, useAuthFormErrors } from '../../../state/user/auth-form.atom';
import { PageRoute } from '../../../constants/routes';

type AuthPageTitle = 'Sign up' | 'Sign in';

type AuthFormProps = {
  title: AuthPageTitle;
  pageToSwitchTitle: AuthPageTitle;
  pageToSwitchDescription: string;
  pageToSwitchRoute: PageRoute;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
};

const AuthForm: React.FC<AuthFormProps> = (props) => {
  const {
    title,
    pageToSwitchTitle,
    pageToSwitchDescription,
    pageToSwitchRoute,
    onSubmit,
  } = props;

  const { email, password } = useAuthForm();
  const { email: emailError, password: passwordError } = useAuthFormErrors();

  const navigate = useNavigate();

  const [shouldValidate, setShouldValidate] = useState<boolean>(false);

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    event.preventDefault();
    updateAuthForm({ email: event.target.value });
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    event.preventDefault();
    updateAuthForm({ password: event.target.value });
  };

  const handleSwitchAuthPageClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    navigate(pageToSwitchRoute);
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AuthFormContainer direction='column' justifyContent='space-between'>
        <Card variant='outlined'>
          <Box
            component='form'
            onSubmit={onSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Typography
              component='h1'
              variant='h4'
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
            >
              {title}
            </Typography>
            <FormControl>
              <FormLabel htmlFor='email'>Email</FormLabel>
              <TextField
                required
                fullWidth
                id='email'
                placeholder='your@email.com'
                name='email'
                autoComplete='email'
                variant='outlined'
                error={shouldValidate && !!emailError}
                helperText={emailError}
                color={emailError ? 'error' : 'primary'}
                value={email}
                onChange={handleEmailChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='password'>Password</FormLabel>
              <TextField
                required
                fullWidth
                name='password'
                placeholder='••••••'
                type='password'
                id='password'
                autoComplete='new-password'
                variant='outlined'
                error={shouldValidate && !!passwordError}
                helperText={passwordError}
                color={passwordError ? 'error' : 'primary'}
                value={password}
                onChange={handlePasswordChange}
              />
            </FormControl>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              onClick={() => setShouldValidate(true)}
            >
              {title}
            </Button>
            <Typography sx={{ textAlign: 'center' }}>
              {pageToSwitchDescription}{' '}
              <span>
                <Link
                  variant='body2'
                  sx={{ alignSelf: 'center' }}
                  style={{ cursor: 'pointer' }}
                  onClick={handleSwitchAuthPageClick}
                >
                  {pageToSwitchTitle}
                </Link>
              </span>
            </Typography>
          </Box>
        </Card>
      </AuthFormContainer>
    </AppTheme>
  );
};

export default AuthForm;
