import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ROUTES } from './constants/routes';
import HomePage from './pages/home-page';
import SignUpPage from './pages/sign-up-page';
import SignInPage from './pages/sign-in-page';
import PrivateRoute from './features/auth/private-route';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={ROUTES.home}
          element={(
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          )}
        />
        <Route path={ROUTES.signUp} element={<SignUpPage />} />
        <Route path={ROUTES.signIn} element={<SignInPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
