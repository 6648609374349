import { atom, getDefaultStore, useAtomValue } from 'jotai';

const DEFAULT_AUTH_FORM: TUserCreateParams = {
  email: '',
  password: '',
};

const EMAIL_REGEX = /\S+@\S+\.\S+/;
const PASSWORD_MIN_LENGTH = 5;

const DEFAULT_ERROR_MESSAGES: TUserCreateParams = {
  email: 'Please enter a valid email address.',
  password: `Password must be at least ${PASSWORD_MIN_LENGTH} characters long.`,
};

const authFormAtom = atom<TUserCreateParams>(DEFAULT_AUTH_FORM);
const authFormErrorsAtom = atom<TUserCreateParams>((get) => {
  const { email, password } = get(authFormAtom);

  let [emailError, passwordError] = ['', ''];

  if (!email || !EMAIL_REGEX.test(email)) {
    emailError = DEFAULT_ERROR_MESSAGES.email;
  }

  if (!password || password.length < PASSWORD_MIN_LENGTH) {
    passwordError = DEFAULT_ERROR_MESSAGES.password;
  }

  return {
    email: emailError,
    password: passwordError,
  }
});

const getAuthForm = (): TUserCreateParams => getDefaultStore().get(authFormAtom);
const setAuthForm = (newAuthForm: TUserCreateParams): void => getDefaultStore().set(authFormAtom, newAuthForm);

export const useAuthForm = (): TUserCreateParams => useAtomValue(authFormAtom);
export const useAuthFormErrors = (): TUserCreateParams => useAtomValue(authFormErrorsAtom);

export const updateAuthForm = (partialAuthForm: Partial<TUserCreateParams>): void => {
  const currentAuthForm = getAuthForm();
  setAuthForm({
    ...currentAuthForm,
    ...partialAuthForm,
  });
};
