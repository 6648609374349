import { atom, getDefaultStore, useAtomValue } from 'jotai';

const DEFAULT_WALLET: TWalletWithoutPrivateKey = {
  id: -1,
  walletId: -1,
  address: '',
  publicKey: '',
  balance: 0,
};

const currentWalletAtom = atom<TWalletWithoutPrivateKey>(DEFAULT_WALLET);

const setCurrentWallet = (currentWallet: TWalletWithoutPrivateKey): void => getDefaultStore().set(currentWalletAtom, currentWallet);

export const useCurrentWallet = (): TWalletWithoutPrivateKey => useAtomValue(currentWalletAtom);

export const updateCurrentWallet = (currentWallet: TWalletWithoutPrivateKey): void => {
  setCurrentWallet(currentWallet);
};
