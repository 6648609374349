import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useCurrentWallet } from '../../../../state/wallets/current-wallet.atom';
import { Typography } from '@mui/material';
import { openNotification } from '../../../../state/notification/notification.atom';
import { copyToClipboard } from '../../../../utils/clipboard';

const ReceiveModal: React.FC<TModalProps> = (props) => {
  const { isVisible, onClose } = props;

  const { address } = useCurrentWallet();

  const handleClickCopyAddress: React.MouseEventHandler<HTMLPreElement> = async (event) => {
    event.preventDefault();
    await copyToClipboard(address);
    openNotification('Your address is copied to clipboard');
  };

  return (
    <>
      <Dialog
        open={isVisible}
        onClose={onClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => event.preventDefault(),
        }}
      >
        <DialogTitle>Receive</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Copy this address to receive tokens
          </DialogContentText>
          <Typography
            component='pre'
            style={{ cursor: 'pointer' }}
            onClick={handleClickCopyAddress}
          >
            {address}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReceiveModal;
