import { atom, getDefaultStore, useAtomValue } from 'jotai';

const EMPTY_MESSAGE = '';

const notificationMessageAtom = atom<string>(EMPTY_MESSAGE);

const setNotificationMessage = (message: string): void => getDefaultStore().set(notificationMessageAtom, message);

export const useNotificationMessage = (): string => useAtomValue(notificationMessageAtom);

export const openNotification = (message: string): void => {
  setNotificationMessage(message);
};

export const closeNotification = (): void => {
  setNotificationMessage(EMPTY_MESSAGE);
};
