import React from 'react';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import { Typography } from '@mui/material';

import TransactionIcon from './transaction-icon';
import { copyToClipboard } from '../../../../utils/clipboard';
import { openNotification } from '../../../../state/notification/notification.atom';
import { openTransactionInTon } from '../../../../utils/ton';
import Tooltip from '../../../../components/tooltip';

export type TransactionType = 'sent' | 'received';

type TransactionsListItemProps = Pick<TTransaction, 'id' | 'createdAt' | 'transactionTonHash'> & {
  peerAddress: TTransaction['senderAddress'] | TTransaction['receiverAddress'];
  transactionType: TransactionType;
};

// TODO: add virualization & pagination for an optimized perfomance
const TransactionsListItem: React.FC<TransactionsListItemProps> = (props) => {
  const { createdAt, transactionTonHash, peerAddress, transactionType } = props;

  const handleClickCopyPeerAddress: React.MouseEventHandler<HTMLPreElement> = async (event) => {
    event.preventDefault();
    await copyToClipboard(peerAddress);
    openNotification('Peer address is copied to clipboard');
  };

  const handleClickTransactionHash: React.MouseEventHandler<HTMLPreElement> = (event) => {
    event.preventDefault();
    openTransactionInTon(transactionTonHash);
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        align='right'
        variant='body2'
        color='text.secondary'
      >
        <Typography variant='caption' component='div'>
          {new Date(createdAt).toLocaleTimeString()}
        </Typography>
        <Typography variant='caption' component='div'>
          {new Date(createdAt).toLocaleDateString()}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
          <TransactionIcon transactionType={transactionType} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '10px', px: 2 }}>
        <Tooltip title='View transaction details in TON'>
          <Typography
            variant='caption'
            component='pre'
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '180px',
              cursor: 'pointer',
            }}
            onClick={handleClickTransactionHash}
          >
            {transactionTonHash}
          </Typography>
        </Tooltip>
        <Tooltip title='Copy address'>
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '128px',
              cursor: 'pointer',
            }}
            onClick={handleClickCopyPeerAddress}
          >
            {peerAddress}
          </Typography>
        </Tooltip>
      </TimelineContent>
    </TimelineItem>
  );
};

export default TransactionsListItem;
