import React from 'react';
import Timeline from '@mui/lab/Timeline';

import Spinner from '../../../components/spinner';
import { useAreTransactionsLoading, useTransactions } from '../../../state/transactions/transactions.atom';
import TransactionsListItem, { TransactionType } from './transactions-list-item';
import { useCurrentWallet } from '../../../state/wallets/current-wallet.atom';

const TransactionsList: React.FC = () => {
  const transactions = useTransactions();
  const areTransactionsLoading = useAreTransactionsLoading();
  const currentWallet = useCurrentWallet();

  if (areTransactionsLoading) {
    return <Spinner />;
  }

  if (!transactions.length) {
    return null;
  }
  
  return (
    <Timeline>
      {transactions.map(({
        id,
        transactionTonHash,
        senderAddress,
        receiverAddress,
        createdAt,
      }) => {
        let transactionType: TransactionType = 'received';
        let peerAddress = senderAddress;
        if (currentWallet.address === senderAddress) {
          transactionType = 'sent';
          peerAddress = receiverAddress;
        }

        return (
          <TransactionsListItem
            key={id}
            id={id}
            transactionTonHash={transactionTonHash}
            peerAddress={peerAddress}
            createdAt={createdAt}
            transactionType={transactionType}
          />
        );
      })}
    </Timeline>
  );
};

export default TransactionsList;
