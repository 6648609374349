import { atom, getDefaultStore, useAtomValue } from 'jotai';

const EMPTY_TRANSACTIONS: TTransaction[] = [];

const transactionsAtom = atom<TTransaction[]>(EMPTY_TRANSACTIONS);
const areTransactionsLoadingAtom = atom<boolean>(true);

const getTransactions = (): TTransaction[] => getDefaultStore().get(transactionsAtom);
const setTransactions = (transactions: TTransaction[]): void => getDefaultStore().set(transactionsAtom, transactions);

const setAreTransactionsLoading = (areLoading: boolean): void => getDefaultStore().set(areTransactionsLoadingAtom, areLoading);

export const useTransactions = (): TTransaction[] => useAtomValue(transactionsAtom);
export const useAreTransactionsLoading = (): boolean => useAtomValue(areTransactionsLoadingAtom);

export const updateTransactions = (transactions: TTransaction[]): void => {
  setTransactions(transactions);
  setAreTransactionsLoading(false);
};

export const unshiftTransaction = (transaction: TTransaction): void => {
  const currentTransactions = getTransactions();
  if (currentTransactions.some(({ id }) => id === transaction.id)) {
    return;
  }

  setTransactions([transaction, ...currentTransactions]);
};

export const clearTransactions = (): void => {
  setTransactions(EMPTY_TRANSACTIONS);
};
